<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title theme-gradient">
                3x3 Technologies: Collaborating
              </h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <div class="rn-service-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row class="sercice-details-content align-center row--35 ">
                  <v-col lg="6" md="12" cols="12">
                    <div class="thumb position-relative">
                      <CoolLightBox
                        :items="github"
                        :index="index"
                        @close="index = null"
                      >
                      </CoolLightBox>

                      <div class="thumbnail position-relative">
                        <div
                          v-for="(image, imageIndex) in github"
                          :key="imageIndex"
                        >
                          <img
                            class="w-100"
                            :src="image.thumb"
                            alt="GitHub Video"
                          />
                          <a
                            @click="index = imageIndex"
                            class="video-popup play__btn"
                            ><span class="play-icon"></span
                          ></a>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="6" md="12" cols="12">
                    <div class="details mt_md--30 mt_sm--30 pt--10">
                      <div class="section-title">
                        <span class="subtitle">Collaborating With:</span>
                        <h2 class="heading-title">
                          <a href="https://github.com/" target="_blank"
                            >GitHub</a
                          >
                        </h2>
                        <p class="description">
                          GitHub is a code hosting platform for version control
                          and collaboration. It lets you and others work
                          together on projects from anywhere. It is one of the
                          most highly used collaboration tools used in the
                          programming realm. It targets programmers who create
                          projects that need to collaborate by giving them a
                          dashboard to host multiple projects and customize who
                          can partake in those projects.
                        </p>
                        <p class="description">
                          The great thing about GitHub is it is free. This
                          allows students to set up an account and push their
                          code to it. With the free tier, there is a maximum of
                          three collaborators, if you want more, you will have
                          to upgrade, but three is usually sufficient. GitHub
                          can be accessed from all devices, but it is
                          recommended to use Windows or macOS when
                          collaborating.
                        </p>
                        <p class="description">
                          GitHub is a collaboration tool that is specific to
                          programmers. With that, though, it can be used for all
                          programmers. It supports all languages.
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="12" md="12" cols="12">
                    <div class="details mt_md--30 mt_sm--30 pt--20">
                      <p class="description">
                        GitHub as a collaboration tool has a simpler user
                        interface and is easy to use but can be more advanced.
                        Being easy to use allows anyone to jump right in and add
                        others to a coding project to start collaborating.
                        GitHub is a visual dashboard that integrates with Git.
                        Git is a version control tool used when programmers are
                        working together on a project that allows them to make
                        changes to the code and put it into one area for the
                        finished product. GitHub allows you to see this and
                        collaborate visually. An example of this is in my
                        classes. I have students break out into groups to create
                        a programming assignment. I have them use GitHub to
                        collaborate when they code the project. This allows them
                        to keep track of changes to the code, see who has done
                        what and compare code to see if something broke and what
                        that was.
                      </p>
                      <p class="description">
                        I believe GitHub promotes and supports the intended
                        learning outcomes for collaboration in the classroom
                        when projects are group-based. GitHub is also a highly
                        used collaboration tool used in the programming
                        industry, which gives students an advantage when getting
                        ready to go into the field. Out of all the different
                        collaboration tools, this is the most used because of
                        all its integrations with code editors, and you can
                        integrate it with project management tools.
                      </p>
                      <p>
                        Below is a YouTube video that demonstrates how to setup
                        and get started with GitHub. Once up and going you will
                        be able to collaborate on coding projects.
                      </p>
                      <p>
                        <iframe
                          width="100%"
                          height="650"
                          src="https://www.youtube.com/embed/iv8rSLsi1xo"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="rn-service-details ptb--120 bg_color--5">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row class="sercice-details-content align-center row--35 ">
                  <v-col
                    lg="6"
                    md="12"
                    cols="12"
                    order="1"
                    order-md="1"
                    order-lg="2"
                  >
                    <div class="thumb position-relative">
                      <CoolLightBox
                        :items="bitbucket"
                        :index="bitbucketindex"
                        @close="bitbucketindex = null"
                      >
                      </CoolLightBox>

                      <div class="thumbnail position-relative">
                        <div
                          v-for="(image, imageIndex) in bitbucket"
                          :key="imageIndex"
                        >
                          <img
                            class="w-100"
                            :src="image.thumb"
                            alt="BitBucket Video"
                          />
                          <a
                            @click="bitbucketindex = imageIndex"
                            class="video-popup play__btn"
                            ><span class="play-icon"></span
                          ></a>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    lg="6"
                    md="12"
                    cols="12"
                    order="2"
                    order-md="2"
                    order-lg="1"
                  >
                    <div class="details mt_md--30 mt_sm--30 pt--10">
                      <div class="section-title">
                        <span class="subtitle">Collaborating With:</span>
                        <h2 class="heading-title">
                          <a href="https://bitbucket.org/" target="_blank"
                            >BitBucket</a
                          >
                        </h2>
                        <p class="description">
                          Bitbucket is a Git repository management solution
                          designed for professional teams. It gives you a
                          central place to manage git repositories, collaborate
                          on your source code and guide you through the
                          development flow just like GitHub.
                        </p>
                        <p class="description">
                          The great thing about BitBucket is it has a free tier,
                          but to get the most out of it, you would want to pay
                          and get all their other applications that integrate
                          for a better workflow. A nice thing is for the free
                          tier BitBucket supports teams 5 and smaller compared
                          to GitHub with 3. BitBucket is similar to GitHub, but
                          they have their own app integrations, but it allows
                          students to set up an account and push their code to
                          it. BitBucket can be accessed from all devices, but it
                          is recommended to use Windows or macOS when
                          collaborating.
                        </p>
                        <p class="description">
                          BitBucket is a collaboration tool that is specific to
                          programmers. With that, though, it can be used for all
                          programmers. It supports all languages. As it is
                          similar to GitHub, I feel like BitBucket appeals to
                          some more organizations due to the project management
                          integration it has.
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="12" md="12" cols="12" order="3" order-md="3">
                    <div class="details mt_md--30 mt_sm--30 pt--30">
                      <p class="description">
                        BitBucket as a collaboration tool has a simple user
                        interface and is easy to use but can be more advanced.
                        Being easy to use allows anyone to jump right in and add
                        others to a coding project to start collaborating. Like
                        GitHub, BitBucket is a visual dashboard that integrates
                        with Git.
                      </p>
                      <p class="description">
                        I believe BitBucket promotes and supports the intended
                        learning outcomes for collaboration in the classroom
                        when projects are group-based. BitBucket is also a
                        highly used collaboration tool used in the programming
                        industry for professionals if other Atlassian
                        applications are used, which gives students an advantage
                        when getting ready to go into the field. Even though
                        this has similarities to GitHub, it is still a good
                        collaboration tool that offers a similar experience.
                      </p>
                      <p class="description">
                        Below is a YouTube video that demonstrates how to setup
                        and get started with BitBucket. Once up and going you
                        will be able collaborate with your code.
                      </p>
                      <p>
                        <iframe
                          width="100%"
                          height="650"
                          src="https://www.youtube.com/embed/M44nEyd_5To"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="rn-service-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row class="sercice-details-content align-center row--35 ">
                  <v-col lg="6" md="12" cols="12">
                    <div class="thumb position-relative">
                      <CoolLightBox
                        :items="code"
                        :index="codeindex"
                        @close="codeindex = null"
                      >
                      </CoolLightBox>

                      <div class="thumbnail position-relative">
                        <div
                          v-for="(image, imageIndex) in code"
                          :key="imageIndex"
                        >
                          <img
                            class="w-100"
                            :src="image.thumb"
                            alt="Figma Video"
                          />
                          <a
                            @click="codeindex = imageIndex"
                            class="video-popup play__btn"
                            ><span class="play-icon"></span
                          ></a>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="6" md="12" cols="12">
                    <div class="details mt_md--30 mt_sm--30 pt--10">
                      <div class="section-title">
                        <span class="subtitle">Collaborating With:</span>
                        <h2 class="heading-title">
                          <a href="https://codeanywhere.com/" target="_blank"
                            >Codeanywhere</a
                          >
                        </h2>
                        <p class="description">
                          Codeanywhere is a Cloud IDE that saves you time by
                          developing a development environment in seconds,
                          enabling you to code, learn, build, and collaborate on
                          projects. Cloud IDE is a new technology but has a lot
                          of benefits as a collaboration tool.
                        </p>
                        <p class="description">
                          Codeanywhere is a free tool where students can try it
                          out for free, but it starts to shine when you pay.
                          They have a basic package for $3.60 a month that
                          allows you to have a container, live collaboration,
                          and multiple connections for servers. The big thing
                          here is the live collaboration. This allows each
                          student to see changes synchronously, where there can
                          be delays when using GitHub or BitBucket.
                        </p>
                        <p class="description">
                          The target audience for Codeanywhere is programmers
                          who need to collaborate anywhere. It allows those
                          programmers to collaborate on mobile phones and
                          personal computers. The big thing here is it will
                          enable you also to edit the code when collaborating on
                          any device where other editors don't support mobile
                          devices like this.
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="12" md="12" cols="12">
                    <div class="details mt_md--30 mt_sm--30 pt--30">
                      <p class="description">
                        Codeanywhere is the ultimate package for collaboration
                        with those wanting to edit their code and have the git
                        integration like above. This isn't seen very often and
                        is a new idea for programming, but I see it taking off
                        mainstream as it has a lot of convenience for getting
                        projects up and going quickly with real-time
                        collaboration. I see Cloud IDE expanding in the future,
                        so jumping into Codeanywehre now would be an advantage.
                        I believe Codeanywhere promotes and supports the
                        intended learning outcomes for students trying to
                        collaborate. If I could get a license for my students to
                        use this, it would have everything needed to be a
                        successful programmer. I look forward to seeing how
                        Cloud IDE will look in the future.
                      </p>
                      <p class="description">
                        Below is a YouTube video that demonstrates how to setup
                        and get started with Codeanywhere. Once up and going you
                        will be able collaborate with your code.
                      </p>
                      <p class="description">
                        <iframe
                          width="100%"
                          height="650"
                          src="https://www.youtube.com/embed/WESSQrre8WY"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p></div
                  ></v-col>
                </v-row>
                <div class="portfolio-details-btn mt--30">
                  <router-link class="btn-default btn-border" to="/LDTE5020"
                    >Back to LDTE 5020</router-link
                  >
                </div>
                <!-- End Single Content  -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <Footer />
  </div>
</template>

<script>
import feather from "feather-icons";
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/FooterTwo";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      logo: require("../../assets/images/logo/logo.png"),
      github: [
        {
          thumb: require("../../assets/images/github.jpg"),
          src: "https://youtu.be/w3jLJU7DT5E",
        },
      ],
      bitbucket: [
        {
          thumb: require("../../assets/images/bitbucket.jpg"),
          src: "https://youtu.be/v-B1YwWJte0",
        },
      ],
      code: [
        {
          thumb: require("../../assets/images/codeanywhere.jpg"),
          src: "https://youtu.be/LV25OUG5vqc",
        },
      ],
      index: null,
      bitbucketindex: null,
      codeindex: null,
    };
  },

  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
<style scoped>
.v-application a {
  color: black !important;
}
</style>
